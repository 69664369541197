import React, { useState, useEffect } from "react";
import './assets/css/tailwind.css';
import './assets/scss/tailwind.scss';
import './assets/scss/icons.scss';

import {
  Route,
  Router,
  Routes
} from "react-router-dom";


import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from "./redux/actions/auth";
import { ROLES } from './config/Roles';
import RequireAuth from './component/auth/RequireAuth';

import LandingPage from "./pages/LandingPage.js";
import PricingPage from "./pages/PricingPage";
import HowItWorks from "./pages/HowItWorks";
import ContactPage from "./pages/ContactPage";
import FeaturesPage from "./pages/FeaturesPage.jsx";
import NotFound404 from './pages/NotFound404';

import Login from './pages/auth/login';
import Signup from './pages/auth/signup';
import Activate from './pages/auth/activate';
import ResetPassword from './pages/auth/reset-password';
import ResetPasswordConfirm from './pages/auth/reset-password-confirm';

import RideList from "./pages/ridesv2/RideList";
import RideDetail from "./pages/ridesv2/RideDetail.jsx";
import RideCreate from "./pages/ridesv2/RideCreate.jsx";

import AddressList from "./pages/addressbook/AddressList";
import AddressDetail from "./pages/addressbook/AddressDetail";
import AddressCreate from "./pages/addressbook/AddressCreate";

import CompanyDetail from "./pages/company/CompanyDetail";
import CompanyMembers from "./pages/company/CompanyMembers";

import CarCreate from "./pages/cars/CarCreate";
import CarList from "./pages/cars/CarList.jsx";
import CarDetail from "./pages/cars/CarDetail.jsx";
import Profile from "./pages/profile/Profile.jsx";
import ProfileSelf from "./pages/profile/ProfileSelf.jsx";
import Invite from "./pages/auth/invite.jsx";
import InviteAccept from "./pages/auth/inviteAccept.jsx";


export default function App() {
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);


  useEffect(() => {


    // If authenticated, attempt to load user data
    if (isAuthenticated && user === null) {
      dispatch(fetchUserData());
    }
  }, [isAuthenticated, user, dispatch]);


  return (
    <>


      <Routes >
        <Route path="/" element={<LandingPage />} />
        <Route path="/index" element={<LandingPage />} />

        <Route path="/home" element={<LandingPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/features" element={<FeaturesPage />} />
        {/* auth pages */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />


        <Route path="/activate/:uid/:token" element={<Activate />} />
        <Route path="/invite/:token/:encodedemail/" element={<InviteAccept />} />

        {/* member content */}
        <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.USER]} />}>

          <Route path="profile/:id" element={<Profile />} />
          <Route path="profile" element={<ProfileSelf />} />

          <Route path="/cars/">
            {/* <Route path="" element={<CarList />} /> */}
            <Route path="create/" element={<CarCreate />} />
            <Route path=":carId" element={<CarDetail />} />
            <Route path="" element={<CarList />} />


          </Route>
          <Route path="/rides/" >

            <Route path="create/" element={<RideCreate />} />
            <Route path=":rideId" element={<RideDetail />} />
            <Route path="" element={<RideList />} />
          </Route>


          <Route path="/addressbook/" >
            <Route path="create" element={<AddressCreate />} />
            <Route path="" element={<AddressList />} />
            <Route path=":addressId" element={<AddressDetail />} />

          </Route>

          <Route path="/company/" >
            <Route path="invite" element={<Invite />} />
            <Route path="me" element={<CompanyDetail />} />
            <Route path="members" element={<CompanyMembers />} />
          </Route>


        </Route>



        {/* service/marketing */}

        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="*" element={< NotFound404 />} />

      </Routes>

    </>
  );

}
