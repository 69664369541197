
//The axiosInstance serves as a crucial component for managing authentication in our application. 
//It is designed to streamline the process of handling access tokens and responding to authentication-related 
//challenges from the server. By utilizing Axios interceptors, this configuration file automatically attaches access 
//tokens to outgoing API requests, ensuring that each request is authenticated. Additionally, it responds to 401 (Unauthorized) 
// and user authentication status across the application. This setup enhances security and simplifies the integration of token-based authentication in our codebase.

import axios from 'axios';
import { createToast } from '../redux/actions/toast';
import store from '../redux/store';
import { apiCallFailure, apiCallStart, apiCallSuccess } from '../redux/actions/api';
import { AUTH_ERROR } from '../redux/constants/action-types';
import { isExpired, refreshAccessToken } from './getTokens';
import handle400Errors from './handle400Errors';

const instance = axios.create({
  baseURL: '', // Replace with your API URL (empty as proxy is used in package.json)
});


instance.interceptors.request.use(async (config) => {

  store.dispatch(apiCallStart());

  // Check if request has FormData and modify headers for file uploads
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  const access = localStorage.getItem('access');
  const refresh = localStorage.getItem('refresh');
  // Get tokens from local storage

  if (!access || !refresh) return config; // if no tokens, return the request


  // check if access token is valid, if valid attach it to the request
  if (!isExpired(access)) {
    config.headers.Authorization = `Bearer ${access}`;
    return config
  }


  // Get a new access token
  return axios.post('/auth/jwt/refresh/', { "refresh": refresh })
    .then((res) => {

      localStorage.setItem('access', res.data.access);
      config.headers.Authorization = `Bearer ${res.data.access}`;

      return config; // Return the modified config after token refresh
    })
    .catch((err) => {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      window.location.href = '/login';

      throw err; // Rethrow the error to be caught by the caller
    });


}, (error) => Promise.reject(error));
instance.interceptors.response.use(
  (response) => {

    store.dispatch(apiCallSuccess());

    return response
  },
  (error) => {
    store.dispatch(apiCallFailure(error));

    if (error.response) {
      const status = error.response.status;


      if (status === 401) {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        // redirect to login page
        window.location.href = '/login';
        

      }
      if (status === 403) {
        // Forbidden: Dispatch a toast message
        store.dispatch(createToast("Not authorized", "error"));

      }

      if (status === 400) {
        // Bad request: Dispatch a toast message

        handle400Errors(error);

      }

    } else {
      // Network error: Dispatch a network error message

      store.dispatch(createToast("Network error. Please try again.", "error"));
    }

    return Promise.reject(error);
  }
);
export default instance;

